<script setup lang="ts">
import NestedList, { NestedListNode } from "./Components.NestedList.vue";
import { onMounted, ref, Ref } from "vue";
import MenuSearch from "./Components.MenuSearch.vue";

let nodes: Ref<NestedListNode> = ref([]);

onMounted(async () => {
    // @ts-ignore
    // Server side generated menu items
    
    let data: any = ssgManualsPayload;
    let nodeValues = data.map((item: any) => mapToNestedListNode(item));

    nodes.value = nodeValues;
    
});

function mapToNestedListNode(item: any, getParent?: () => NestedListNode): NestedListNode {
    
    let key = 'Children' in item ? 'Children' : 'Sections' in item ? 'Sections' : null;
    let children: any[] = [];
    let node: Partial<NestedListNode> = {};

    function getParentNode() {
        return this;
    }

    if (key == 'Children') {
        children = [...Object.values(item.Children).map(x => mapToNestedListNode(x, getParentNode.bind(node))), ...item.ContentItems.map(x => mapToNestedListNode(x, getParentNode.bind(node)))];
    } else if (key == 'Sections') {
        children = Object.values(item.Sections)
            .sort(((a: any , b: any) => a.SortOrder - b.SortOrder))
            .map(x => mapToNestedListNode(x, getParentNode.bind(node)))
    }

    let labelKey = !key ? 'Title' : key == 'Sections' ? 'ManualName' : 'Name';
    let isSectionItem = 'ContentItemId' in item;
    let isContentItem = 'SectionItemId' in item;
    let value = isSectionItem ? item['ContentItemId'] && item['Id'] : isContentItem ? item['SectionItemId'] : null;
    value = !!value ? isContentItem ? `MSI${value}` : `MS${value}` : null;
    
    node.ContentItemId = isSectionItem ? item['ContentItemId'] : item['Id'];
    node.label = item[labelKey];
    node.value = value;
    node.children = children;
    node.getParent = getParent;
    node.id = item['Id'];
    node.type = item['Type'];
    node.area = item["AreaId"];
    node.manual = item["ManualId"];

    if (item['ContentItemName']) {
        node.name = item['ContentItemName'];
    }

    // @ts-ignore
    return node;
}

async function handleNavClick(val: string | null) {

    // let url = new URL(window.location.href);
    // let navigateTo = (href: string) => window.location.href = href;

    // if (val?.includes('MS')) {
        // if (url.searchParams.has('Name')) {
            // url.searchParams.delete('Name');
        // }

        // url.searchParams.set('ID', val);
        // navigateTo(url.href);
    // }

    //document.querySelectorAll('.menu-highlight').forEach((e) = > {

    //});

}



</script>

<template>
    <div class="d-flex position-relative mb-10">
        <MenuSearch />
    </div>
    <div class="d-flex flex-column flex-grow-1" style="margin-top: 50px">
        <NestedList v-for="node in nodes" :node="node" @onClick="handleNavClick" />
    </div>
</template>

<style>
#app {
    display: flex;
    flex-grow: 1;
}

.container {
    flex-grow: 1;
}
</style>